<template>
  <div class="login-wrapper">
    <div style="text-align: center" v-if="loader">
      <div id="loading"></div>
    </div>
    <div v-else>
      <div class="text-center login-header">Change Email Address</div>
      <div v-if="stage === 'details'" class="login-input-wrapper">
        <form>
          <SfInput
            type="text"
            label="Email address"
            name="emailAddress"
            v-model.lazy="inputText"
            :valid="!$v.inputText.$error"
            :error-message="$t('Please enter a valid email address.')"
            autocomplete="username"
            @blur="$v.inputText.$touch()"
            class="form__element phone-or-email"
          />
          <div class="login-error" v-if="errorMessage">
            <span class="error-message">{{ errorMessage }}</span>
          </div>
          <div class="actions">
            <SfButton
              type="button"
              size="small"
              :disabled="!isEmailChanged || $v.inputText.$invalid"
              @click="changeEmail"
            >
              Continue
            </SfButton>
            <SfButton type="button" class="sf-button--text" @click="cancel"
              >Cancel</SfButton
            >
          </div>
        </form>
      </div>
      <div v-if="stage === 'confirm'" class="otp-container">
        <div>
          <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
          <label>
            Confirm change to
            <b>{{ inputText }}</b> ?
          </label>
          <label v-if="currentEmail">
            You will not be able to login using <b>{{ currentEmail }}</b> after
            this action.
          </label>
          <div class="actions">
            <SfButton @click="ProceedWithChange">Proceed</SfButton>
            <SfButton class="sf-button--pure" @click="cancel">CANCEL</SfButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountry } from "@lib/utility/ipUtils";
import { SfButton, SfInput } from "@lib/components";
import { mapActions, mapGetters } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
import config from "@config/config";
import {
  required,
  numeric,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  components: {
    SfInput,
    SfButton,
  },
  data() {
    return {
      inputText: "",
      formattedInput: "",
      password: "",
      stage: "details",
      showSignUpButton: true,
      loader: false,
      errorMessage: null,
      isInputValid: false,
      countryCode: null,
      numberCheckTimer: null,
      emailAddress: null,
      emailValidationError: null,
    };
  },
  validations() {
    return {
      inputText: {
        required,
        email,
      },
    };
  },
  computed: {
    ...mapGetters({
      store: "store",
      currentEmail: "user/getUserEmail",
    }),
    isEmailChanged() {
      return this.currentEmail != this.inputText;
    },
  },
  methods: {
    ...mapActions({
      updateEmailAddress: "user/updateEmailAddress",
    }),
    changeEmail() {
      // Show confirm change
      // When user
      this.stage = "confirm";
    },
    async ProceedWithChange() {
      this.errorMessage = "";
      if (this.$v.inputText.$invalid) {
        this.$v.inputText.$touch();
        return;
      }

      this.loader = true;
      try {
        this.formattedInput = this.inputText;
        const { success, errorMessage } = await this.updateEmailAddress({
          email: this.inputText,
        });
        if (success) {
          let successNotification = notifications.createNotification({
            type: "success",
            message: "Your email was updated.",
          });
          this.$store.dispatch(
            "notification/spawnNotification",
            successNotification
          );
          this.$emit("close");
        } else {
          this.errorMessage = errorMessage;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;
        let errorNotification = notifications.createNotification({
          type: "danger",
          message:
            error.message ||
            `An error occurred while updating your email. Please try again after some time.`,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          errorNotification
        );
        this.$emit("close");
        console.error(error);
      }
    },
    cancel() {
      this.$emit("close");
    },
  },
  mounted() {
    this.inputText = this.currentEmail;
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers";

.login-wrapper {
  .login-header {
    font-size: 1.4rem;
    text-align: center;
    padding: 0.5rem 0 2rem 0;
  }
}

.login-input-wrapper {
  label {
    font-size: 0.95rem;
  }
  input {
    --input-border-color: var(--c-black);
    &:focus {
      outline: none;
    }
  }
}

.text-center {
  text-align: center;
}

.login-error {
  color: var(--c-danger);
  font-size: 0.95rem;
  padding: 0.5rem 0;

  .error-help {
    display: block;
    padding-top: 0.5rem;
  }
}

.login-wrapper {
  padding: 30px 20px;

  .iti {
    margin: 30px 0 10px 0;
    width: 100%;
    border-radius: 0.25rem;

    input {
      width: 100%;
      letter-spacing: 0.1rem;
      font-size: 1.1rem;
      padding-left: 56px;
      padding-right: 12px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    input:focus {
      outline: none;
      border-bottom: 2px solid var(--c-primary);
    }

    .iti__selected-flag:focus {
      outline: none;
    }
  }

  .phone-validation-error {
    height: 1.2rem;
    text-align: right;
    color: var(--c-danger);
  }

  .actions .sf-button {
    float: right;
    margin-top: 10px;
  }

  .sf-button {
    --button-padding: var(--spacer-xs) var(--spacer-sm);
    --button-text-decoration: none;
    border-radius: 0.25rem;
    text-transform: none;

    &--pure {
      color: var(--c-primary);
    }
  }

  .otp-container {
    label {
      display: block;
      margin-bottom: 2rem;
      line-height: 2rem;
    }

    .sf-input {
      --input-border-color: var(--c-primary);
      --input-font-size: 1.5rem;

      input:focus,
      input:active {
        outline: none;
      }
    }

    .error {
      color: var(--c-danger);
      padding: 0 0 1rem 0;
    }
  }

  .phone-number-edit {
    color: #4285f4;
    font-variant: normal;
    font-weight: 400;
    font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }

  .actions {
    overflow: hidden;
    padding: 20px 0;

    .sf-button {
      --button-padding: var(--spacer-xs) var(--spacer-sm);
      border-radius: 0.25rem;
      --button-font-size: 1rem;

      &--text {
        color: var(--c-primary);
      }
    }
  }

  .resend-button-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#firebaseui-auth-container {
  @include for-desktop {
    margin: 48px 32px;
  }

  .firebaseui-card-content {
    @include for-mobile {
      padding: 0;
    }
  }
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin-top: 120px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
